import LandingDbSource from "../../data/landingdb-source";
import { content1Page } from "../component/MediaPartner/media-partner-creator";

const MediaPartner = {
  async render() {
    return `
    <div class="content textPutih content-MediaPartner" tabindex="0">
      <section id="content1" class="content1"></section>
    </div>
    `;
  },

  async afterRender() {
    document.title = "MEDIA PARTNER";
    try {
      /*
        content 1
      */
      const content1Source = await LandingDbSource.LandingMP();
      const content1Container = document.querySelector("#content1");
      content1Container.innerHTML = content1Page(content1Source);

    } catch (error) {
      console.log(error);
    }
  },
};
// content3Source.forEach((kocaks) => {
//   content3Container.innerHTML += content3Page(kocaks);
// });
export default MediaPartner;
