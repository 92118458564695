import LandingDbSource from "../../data/landingdb-source";
import {
  content1Page,
  content2Page,
  content3Page,
  content4Page,
} from "../component/profile/profile-creator";
const Profile = {
  async render() {
    return `
      <div class="content textPutih content-profile" tabindex="0">
        <h2 class="Judul" data-aos="fade-down">About Us</h2>
        <section id="content1" class="content1" tabindex="0"></section>
        <section id="content2" class="content2 py-5" tabindex="0"></section>
        <section id="content3" class="content3" tabindex="0"></section>
        <section id="content4" class="content4" tabindex="0"></section>
      </div>
    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    document.title = "PROFILE";
    /*
        content 1
      */
    const content1Container = document.querySelector("#content1");
    content1Container.innerHTML = content1Page();
    /*
      content 2
    */
    const content2SourceMp = await LandingDbSource.LandingMP();
    const content2SourceSp = await LandingDbSource.LandingSP();
    const content2Container = document.querySelector("#content2");
    content2Container.innerHTML = content2Page(
      content2SourceMp,
      content2SourceSp
    );

    /*
      content 3
    */
    const content3Source = await LandingDbSource.SourceProfile();
    const content3Container = document.querySelector("#content3");
    content3Container.innerHTML = content3Page(content3Source);

    // content3Source.forEach((kocaks) => {
    //   content3Container.innerHTML += content3Page(kocaks);
    // });

    /*
      content 4
    */
    const content4Container = document.querySelector("#content4");
    content4Container.innerHTML = content4Page();
    
    const form = document.getElementById("contactForm");
    form.addEventListener("submit", (e) => {
      e.preventDefault();

      // Collect form data
      const email = document.getElementById("email").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;

      // Use FormSubmit to send email
      fetch("https://formspree.io/f/mvgoygao", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          subject: subject,
          message: message,
        }),
      })
        .then((response) => {
          if (response.ok) {
            alert("Pesan berhasil dikirim!");
            form.reset(); // Reset form
          } else {
            alert("Gagal mengirim pesan. Silakan coba lagi.");
          }
        })
        .catch((error) => alert("Terjadi kesalahan: " + error.message));
    });
  },
};

export default Profile;
