import UrlParser from "../../routes/url-parser";
import LandingDbSource from "../../data/landingdb-source";
import { checkoutPage } from "../component/order/orderPage";

const orderPage = {
  async render() {
    return `
    <div class="content textPutih" id="content" tabindex="0"></div>   
    `;
  },
  async afterRender() {
    document.title = "ORDER";
    const url = UrlParser.parseActiveUrlWithoutCombiner();
    try {
    //   const checkoutData = await LandingDbSource.checkout(url.id);
    //   console.log(checkoutData);
      const checkoutContainer = document.querySelector("#content");
      checkoutContainer.innerHTML = checkoutPage();

    } catch (error) {}
  },
};

export { orderPage };
