import { content1Page } from "../component/regis-creator";
import API_ENDPOINT from "../../globals/api-endpoint";

const Regis = {
  async render() {
    return `
      <div class="content textPutih content-register" tabindex="0">
        <section id="content1" class="content1"></section>
      </div>
    `;
  },

  async afterRender() {
    document.title = "REGISTER";
    try {
      const content1Container = document.querySelector("#content1");
      content1Container.innerHTML = content1Page();

      // Handling toggle password visibility
      const togglePassword = document.getElementById("toggle-password");
      const toggleConfirmPassword = document.getElementById(
        "toggle-confirm-password"
      );
      const passwordField = document.getElementById("password");
      const confirmPasswordField = document.getElementById("confirm-password");

      togglePassword.addEventListener("click", () => {
        const type = passwordField.type === "password" ? "text" : "password";
        passwordField.type = type;
        togglePassword.querySelector("i").classList.toggle("bi-eye-slash");
      });

      toggleConfirmPassword.addEventListener("click", () => {
        const type =
          confirmPasswordField.type === "password" ? "text" : "password";
        confirmPasswordField.type = type;
        toggleConfirmPassword
          .querySelector("i")
          .classList.toggle("bi-eye-slash");
      });

      // Handling form submission
      const registerForm = document.getElementById("register-form");
      registerForm.addEventListener("submit", async (event) => {
        event.preventDefault();

        const username = document.getElementById("username").value;
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const confirmPassword =
          document.getElementById("confirm-password").value;

        // Validate form inputs
        if (password !== confirmPassword) {
          Swal.fire({
            icon: "error",
            title: "Kesalahan",
            text: "Kata sandi dan konfirmasi kata sandi tidak cocok.",
          });
          return;
        }

        try {
          const response = await fetch(API_ENDPOINT.REGISTER, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username,
              email,
              password,
              fullName: username,
            }),
          });

          const data = await response.json();

          if (data.error) {
            Swal.fire({
              icon: "error",
              title: "Gagal",
              text: data.message,
            });
          } else {
            const { token, user } = data;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", token);

            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Pendaftaran berhasil! Silakan masuk.",
            }).then(() => {
              window.location.href = "#/user/dashboard"; // Redirect
              window.location.reload();
            });
          }
        } catch (error) {
          console.error("Register failed:", error);
          Swal.fire({
            icon: "error",
            title: "Kesalahan",
            text: "Terjadi kesalahan. Silakan coba lagi.",
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
};

export default Regis;
